
// 自定义一个通用键名
const INFO_KEY = 'hm_shopping_info'
// 获得个人信息
export const getInfo = () => {
  // 默认值
  const defaultObj = { token: '', userId: '' }
  const result = localStorage.getItem(INFO_KEY)
  return result ? JSON.parse(result) : defaultObj
}

// 设置个人信息
export const setInfo = (obj) => {
  localStorage.setItem(INFO_KEY, JSON.stringify(obj))
}
// 删除个人信息
export const removeInfo = () => {
  localStorage.removeItem(INFO_KEY)
}

const HISTORY_KEY = 'history_key'

// 获得历史记录
export const getHistory = () => {
  // 默认值
  const result = localStorage.getItem(HISTORY_KEY)
  return result ? JSON.parse(result) : []
}

// 设置历史记录
export const setHistory = (obj) => {
  localStorage.setItem(HISTORY_KEY, JSON.stringify(obj))
}
// 删除历史记录
export const removeHistory = () => {
  localStorage.removeItem(HISTORY_KEY)
}
