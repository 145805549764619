<template>
    <div></div>
  </template>

<script>
export default {
  // 必须有name 至少两个单词以上组成 大写区分单词
  name: 'myOrder'
}
</script>

  <style>

  </style>
