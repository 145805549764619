<template>
    <div class="header">
      <van-nav-bar
        title="会员登入"
        left-text="返回"
        right-text="按钮"
        left-arrow
        @click-left="$router.back()"
        @click-right="onClickRight"
      />
      <div class="container">
      <div class="title">
        <h3>手机号登录</h3>
        <p>未注册的手机号登录后将自动注册</p>
      </div>

      <div class="form">
        <div class="form-item">
          <input v-model="phone" class="inp" maxlength="11" placeholder="请输入手机号码" type="text">
        </div>
        <div class="form-item">
          <input v-model="picCode" class="inp" maxlength="5" placeholder="请输入图形验证码" type="text">
          <img v-if="picUrl" :src="picUrl" @click="getPicCode" alt="">
        </div>
        <div class="form-item">
          <input v-model="msgCode" class="inp" placeholder="请输入短信验证码" type="text">
          <button @click="getCode">{{ second === totalSecond ? '获取验证码' : second + '秒后重新发送' }}</button>
        </div>
      </div>

      <div  class="login-btn" @click="login">登录</div>
    </div>
    </div>
  </template>

<script>
import { Toast } from 'vant'
import { getPicCode, getMsgCode, codeLogin } from '@/api/login'
export default {
  // 必须有name 至少两个单词以上组成 大写区分单词
  name: 'loginUser',
  data () {
    return {
      picCode: '',
      picKey: '',
      picUrl: '',
      totalSecond: 60, // 总秒数
      second: 60, // 当前秒数，开定时器
      openTime: null,
      phone: '',
      secry: '',
      msgCode: ''
    }
  },
  methods: {
    onClickRight () {
      Toast('按钮')
    },
    // 校验 手机号和 图形验证码
    validFn () {
      if (!/^1[3-9]\d{9}$/.test(this.phone)) {
        this.$toast('请输入正确的手机号')
        return false
      }
      if (!/^\w{4}$/.test(this.picCode)) {
        this.$toast('请输入正确的图形验证码')
        return false
      }
      return true
    },
    // 获取验证码
    async getCode () {
      if (!this.validFn()) {
        return
      }
      // 开启倒计时
      if (!this.openTime && this.second === this.totalSecond) {
        // 发送请求
        const res = await getMsgCode(this.picCode, this.picKey, this.phone)
        console.log(res)
        this.$toast('验证码发送成功')
        this.openTime = setInterval(() => {
          this.second--
          if (this.second <= 0) {
            clearInterval(this.openTime)
            this.openTime = null
            this.second = this.totalSecond
          }
        }, 1000)
      }
    },
    // 获取图形验证码
    async getPicCode () {
      const { data: { base64, key } } = await getPicCode()
      this.picKey = key
      this.picUrl = base64
      Toast('获取图形验证码成功')
    },
    // 登录校验
    async login () {
      if (!this.validFn) {
        return
      }
      if (!/^\d{6}$/.test(this.msgCode)) {
        this.$toast('请输入正确的手机验证码')
        return
      }
      const res = await codeLogin(this.phone, this.msgCode)
      // 登入 存入数据
      this.$store.commit('user/setUserInfo', res.data)
      console.log(res)
      this.$toast('登录成功')
      this.$router.push('/')
    }
  },
  async created () {
    // 会与基本地址进行拼接
    const res = await getPicCode()
    this.picUrl = res.data.base64
    this.picKey = res.data.key
  },
  // 定时器创建后默认不会自动销毁，需要手动销毁
  destroyed () {
    clearInterval(this.openTime)
  }
}
</script>

<style lang="less" scoped>
.container {
  padding: 49px 29px;

  .title {
    margin-bottom: 20px;
    h3 {
      font-size: 26px;
      font-weight: normal;
    }
    p {
      line-height: 40px;
      font-size: 14px;
      color: #b8b8b8;
    }
  }

  .form-item {
    border-bottom: 1px solid #f3f1f2;
    padding: 8px;
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    .inp {
      display: block;
      border: none;
      outline: none;
      height: 32px;
      font-size: 14px;
      flex: 1;
    }
    img {
      width: 94px;
      height: 31px;
    }
    button {
      height: 31px;
      border: none;
      font-size: 13px;
      color: #cea26a;
      background-color: transparent;
      padding-right: 9px;
    }
  }

  .login-btn {
    width: 100%;
    height: 42px;
    margin-top: 39px;
    background: linear-gradient(90deg,#ecb53c,#ff9211);
    color: #fff;
    border-radius: 39px;
    box-shadow: 0 10px 20px 0 rgba(0,0,0,.1);
    letter-spacing: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
  </style>
