import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '@/views/login' // 如果加载的是文件夹下的index开头的根文件 则只需要文件夹路径即可，会自动加载
// import Login from '@/views/login/index'
import Layout from '@/views/layout'
import Myorder from '@/views/myorder'
import Pay from '@/views/pay'
import Prodetail from '@/views/prodetail/index.vue'
import Search from '@/views/search'

import Cart from '@/views/layout/cart.vue'
import Category from '@/views/layout/category.vue'
import Home from '@/views/layout/home.vue'
import User from '@/views/layout/user.vue'
import SearchList from '@/views/search/list.vue'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Layout,
    // 当在一级路由访问/路径 直接访问二级路由/home
    redirect: '/home',
    // 配置二级路由
    children: [
      { path: '/home', component: Home },
      { path: '/cart', component: Cart },
      { path: '/category', component: Category },
      { path: '/user', component: User }
    ]
  },
  { path: '/searchlist', component: SearchList },
  { path: '/login', component: Login },
  { path: '/myorder', component: Myorder },
  { path: '/prodetail:id', component: Prodetail },
  { path: '/pay', component: Pay },
  {
    path: '/search',
    component: Search,
    children: [

    ]
  }
]

const router = new VueRouter({
  routes
})

// 所有路由都会先经过 全局前置导航守卫
// 只有全局前置导航守卫放行才会达到对应的页面

// 全局前置导航守卫
// to: 到哪里去 ， 到哪里去的完整路由信息对象 (路径，参数)
// from: 从哪里来 ， 从哪里来的完整路由信息对象 (路径，参数)
// next() 是否放行
// next() 直接放行，放行到to的路径
// next(路径) 进行跳转到路径

// 定义一个数组，专门用户存放所有需要权限访问的页面
const authUrls = ['/pay', '/myorder'] // 需要权限访问的页面

router.beforeEach((to, from, next) => {
  // to.path 要去的地址 如 /login
  if (!authUrls.includes(to.path)) {
    next()
    return
  }
  // 权限访问
  const token = store.state.getToken
  if (token) {
    next()
    alert(token)
  } else {
    next('/login')
  }
})

export default router
