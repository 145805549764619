import Vue from 'vue'
import Vuex from 'vuex'
// 导入user模块
import user from './modules/user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
    getToken (state) {
      return state.user.userInfo.token
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    // 使用user模块
    user
  }
})
