<template>
  <div>
    <!-- 二级路由出口 -->
    <router-view />
    <!-- 添加router属性 -->
    <van-tabbar route active-color="#ee0a24" inactive-color="#000">
      <!-- 添加to属性 进行路由跳转 -->
    <van-tabbar-item icon="wap-home-o" to="/home" >首页</van-tabbar-item>
    <van-tabbar-item icon="search" to="/category" >分类页</van-tabbar-item>
    <van-tabbar-item icon="cart-o" to="/cart" >购物车</van-tabbar-item>
    <van-tabbar-item icon="user-o" to="/user" >我的</van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  // 必须有name 至少两个单词以上组成 大写区分单词
  name: 'layOut'
}
</script>

<style>

</style>
