import axios from 'axios'
import { Toast } from 'vant'
// 创建 axios实例, 将来对创建出来的实例 进行自定义配置
// 好处: 不会污染原始的 axios 实例
const instance = axios.create({
  // 基本地址
  baseURL: 'http://smart-shop.itheima.net/index.php?s=/api',
  timeout: 5000,
  headers: { platform: 'H5' }
})

// 自定义配置
// 添加请求拦截器
instance.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  // 开启loading加载， 防止背景点击，多次点击
  Toast.loading({
    message: '加载中...',
    forbidClick: true,
    loadingType: '', // 配置loading图标
    duration: 0 // 为零 一直加载 当响应拦截器触发则进行关闭
  })
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
instance.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么 (默认axios会多包装一成data 需要响应拦截器处理一下)
  const res = response.data
  if (res.status !== 200) {
    // 进行错误提醒 Toast 默认单列模式 后面的toast会覆盖前面的
    Toast(res.message)
    // 抛出异常
    return Promise.reject(res.message)
  } else {
    // 正确情况，直接走业务核心逻辑，清除loading效果
    Toast.clear()
  }
  return res
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})
// 导出
export default instance
