// 1. 引入你需要的组件
import { Button, Switch, Tabbar, TabbarItem, NavBar, Toast, Search, Swipe, SwipeItem, Grid, GridItem, Icon } from 'vant'
import Vue from 'vue'
// 2. 引入组件样式
import 'vant/lib/index.css'

// 使用
Vue.use(Button)
Vue.use(Switch)
Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(NavBar)
Vue.use(Search)
Vue.use(Swipe)
Vue.use(Toast)
Vue.use(SwipeItem)
Vue.use(Grid)
Vue.use(GridItem)
Vue.use(Icon)
