<template>
  <div>cart</div>
</template>

<script>
export default {
  name: 'CarT'
}
</script>

<style>

</style>
