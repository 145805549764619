import { getInfo, setInfo } from '@/utils/storage'
export default {
  namespeced: true,
  state () {
    return {
      // 获取个人信息
      userInfo: getInfo()
    }
  },
  mutations: {
    setUserInfo (state, obj) {
      state.userInfo = obj
      // 存储个人信息
      setInfo(obj)
    }
  },
  actions: {},
  getters: {

  }
}
